<script setup lang="ts">

const {title, description,
  facebook,
  twitter,
  instagram,
  whatsapp,
  GetSiteSettings} = useSiteSettings()

await GetSiteSettings()

if(description.value != null && description.value != '')
{
  title.value = title.value + ' | ' + description.value  
}

useHead({
  title: title.value,
  meta: [
    {
      name: 'description',
      content: description.value
    },
    {
      name: 'keywords',
      content: 'benimsaham, benim saham, benim, saham, '+title.value+', '+description.value
    }
  ]
})

const { data, status, error } = await requestWrapper().get(
  '/api/home/default')

const slogans = ref([])
if (data && data.value.data.sliders)
{
  var sliders = data.value.data.sliders
  
  sliders.forEach((item) => {
    //console.log(item)
    slogans.value.push({
      id: item.id,
      title: item.title,
      subtitle: item.subTitle,
      description: item.description,
      image: item.image
    })
  })
}

</script>

<template>
  <div class="flex flex-col min-h-full gap-8">
    <div class="h-96">
      <CarouselHome :slogans="slogans" :upload-url="useRuntimeConfig().public.uploadUrl +'/images/home_defaults/'"/>
    </div>
    <h2 class="text-2xl font-bold text-center">Kayıtlı Maçlar</h2>
    <div class="grid grid-flow-row grid-cols-1 grid-rows-2 md:grid-cols-2 lg:grid-rows-1 lg:grid-cols-4 gap-6 p-8">
      <div v-if="data" v-for="post, index in data.data.posts" class="card shadow-xl image-full cursor-pointer" :class="[index%2 == 0 ? '-rotate-3' : 'rotate-3']" @click="navigateTo(`/post/${post.reference}`)">
          <figure><NuxtImg :src="useRuntimeConfig().public.uploadUrl+'/images/'+post.areaSlug+'/'+ post.image" alt="benimsaham" loading="lazy"/></figure>
          <div class="card-body justify-between items-center">
            <Icon name="material-symbols:play-circle-outline" size="48px" />
          </div>
      </div>
      <div v-else-if="status == 'pending'" v-for="index in 3" class="card shadow-xl image-full" :class="[index%2 == 0 ? '-rotate-3' : 'rotate-3']">
          <figure class="skeleton w-64 h-40"></figure>
          <div class="skeleton card-body">
            <h2 class="skeleton card-title"></h2>
            <p class="skeleton"></p>
          </div>
      </div>
      <div v-else-if="status == 'error'" class="card shadow-xl image-full">
          <div class="card-body">
            <h2 class="card-title">Error</h2>
            <p>{{ error }}</p>
            <p>{{ status }}</p>
            <p>{{ data }}</p>
          </div>
      </div>
      <div v-else class="w-full">
        <p>
          henüz bir video yok
        </p>
      </div>
      
    </div>
        
  </div>
</template>
