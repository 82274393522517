<template>
    <div class="carousel w-full h-full">
        <div v-for="(slogan, index) in props.slogans" :id="`slogan_${index}`" class="carousel-item relative flex flex-col lg:flex-row items-center justify-center w-full h-full group z-0">
            <div class="absolute text-white text-center z-0">
                <h1 class="text-4xl font-bold animate-delay-150" :class="animationPlay ? 'animate-fade-down' : 'animate-reverse'">{{slogan.title}}</h1>
                <h2 class="text-2xl font-bold animate-delay-300" :class="animationPlay ? 'animate-fade-left' : 'animate-reverse'">{{slogan.subtitle}}</h2>
                <p class="text-lg font-semibold animate-delay-500" :class="animationPlay ? 'animate-fade-right' : 'animate-reverse'">{{slogan.description}}</p>
            </div>
            <!-- add images as bacground-->
            <!--<div class="absolute -z-10 w-full h-full bg-cover bg-center bg-no-repeat" :style="{ backgroundImage: `url(${uploadUrl+slogan.image})` }"></div>
            -->
            <NuxtImg :src="uploadUrl+slogan.image" alt="slogan" class="object-contain origin-center w-full h-full" loading="lazy"/>

            <div class="absolute flex justify-between transform -translate-y-1/2 left-2 right-2 top-1/2 z-[1]">
                <button @click="prevSlide" class="btn btn-sm btn-accent btn-circle hidden lg:group-hover:block">
                    <Icon name="ic:chevron-left" />
                </button>
                <button @click="nextSlide" class="btn btn-sm btn-accent btn-circle hidden lg:group-hover:block">
                    <Icon name="ic:chevron-right" />
                </button>
            </div>
            
        </div>
    </div>
    <div class="flex flex-row justify-center w-full">
        <div v-for="(slogan, index) in slogans" :key="index" class="w-2 h-2 mx-1 rounded-full cursor-pointer" :class="currentSlideIndex === index ? 'bg-accent' : 'bg-neutral'" @click="scrollToSlide(index)">
            
        </div>
    </div>
</template>

<script setup lang="ts">

var props = defineProps({
    slogans: {
        type: Array,
        required: true
    },
    uploadUrl: {
        type: String,
        required: true
    }
})
// create auto slide change interval 5 seconds and clear interval when mouse over the carousel item and restart when mouse leave the carousel item 
let interval: any
let currentSlideIndex = ref(0)
let animationPlay = ref(true)
let sliderInterval = 5000
let animationDuration = 1000

const prevSlide = () => {
    animationPlay.value = true
    if(currentSlideIndex.value === 0){
        currentSlideIndex.value = props.slogans.length - 1
    } else {
        currentSlideIndex.value--
    }
    scrollToSlide(currentSlideIndex.value)
    setTimeout(() => {
        animationPlay.value = false
    }, animationDuration)
}

const nextSlide = () => {
    animationPlay.value = true
    if(currentSlideIndex.value === props.slogans.length - 1){
        currentSlideIndex.value = 0
    } else {
        currentSlideIndex.value++
    }
    scrollToSlide(currentSlideIndex.value)
    setTimeout(() => {
        animationPlay.value = false
    }, animationDuration)
}

const scrollToSlide = (index: number) => {
    currentSlideIndex.value = index
    animationPlay.value = true
    setTimeout(() => {
        animationPlay.value = false
    }, animationDuration)
    // change the carousel item with the given index
    const carousel = document.querySelector('.carousel')
    const carouselItems = document.querySelectorAll('.carousel-item')
    carousel?.scrollTo({
        left: carouselItems[index].clientWidth * index,
        behavior: 'smooth'
    })
}

onMounted(() => {
    
    const carousel = document.querySelector('.carousel')
    carousel?.addEventListener('mouseover', () => {
        clearInterval(interval)
    })
    carousel?.addEventListener('mouseleave', () => {
        clearInterval(interval)
        interval = setInterval(() => {
            nextSlide()
        }, sliderInterval)
    })
    if(!interval){
        clearInterval(interval)
        interval = setInterval(() => {
            nextSlide()
        }, sliderInterval)
    }
})

onUnmounted(() => {
    clearInterval(interval)
})

</script>